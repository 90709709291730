
import { defineComponent, ref, watch } from 'vue';

import { PlayCircleTwoTone, PauseCircleTwoTone } from '@ant-design/icons-vue';

export default defineComponent({
  components: {
    PlayCircleTwoTone,
    PauseCircleTwoTone,
  },
  props: {
    videoUrl: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['submit'],
  setup(_props, { emit }) {
    const videoRef = ref();
    const duration = ref<number>(100);
    const slider = ref<[number, number]>([0, 10]);
    const audioStartTime = ref<number>(0);
    const stopAtAudio = ref<string>('true');
    const isPlay = ref(false);
    const stopAtAudioList = [
      { label: 'true', value: 'true' },
      { label: 'false', value: 'false' },
      { label: 'smart', value: 'smart' },
      { label: 'smartFit', value: 'smartFit' },
      { label: 'smartEnd', value: 'smartEnd' },
    ];

    watch(
      () => slider.value[0],
      (newVal) => {
        videoRef.value.currentTime = newVal;
      }
    );

    const onChangeStopAtAudio = (e: any) => {
      stopAtAudio.value = e;
    };

    const request = () => {
      emit('submit');
    };

    const timeUpdate = (e: any) => {
      const currentTime = e.target.currentTime;
      if (currentTime > slider.value[1]) {
        videoRef.value.pause();
        isPlay.value = false;
        videoRef.value.currentTime = slider.value[0];
      }
    };
    const playVideo = () => {
      isPlay.value = true;
      videoRef.value.play();
    };

    const pauseVideo = () => {
      isPlay.value = false;
      videoRef.value.pause();
    };

    const onReadyPlay = (e: any) => {
      duration.value = e?.target?.duration ?? 0;
      slider.value = [0, Math.floor(duration.value / 2)];
    };

    const onChangeSlider = (value: [number, number]) => {
      slider.value = value;
    };

    const onSubmit = () => {
      return {
        api: 'kiosk/',
        params: {
          loopStartTime: slider.value[0],
          loopEndTime: slider.value[1],
          stopAtAudio: stopAtAudio.value,
          audioStartTime: audioStartTime.value,
        },
      };
    };

    return {
      // state
      videoRef,
      slider,
      duration,
      isPlay,
      audioStartTime,
      stopAtAudio,
      stopAtAudioList,
      // func
      request,
      timeUpdate,
      playVideo,
      pauseVideo,
      onReadyPlay,
      onChangeStopAtAudio,
      onChangeSlider,
      onSubmit,
    };
  },
});
