import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, {
    type: "flex",
    gutter: [12, 12]
  }, {
    default: _withCtx(() => [
      (_ctx.$slots.guide)
        ? (_openBlock(), _createBlock(_component_a_col, {
            key: 0,
            span: 24
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_card, {
                title: "Guide",
                style: {"flex":"1"}
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "guide")
                ]),
                _: 3
              })
            ]),
            _: 3
          }))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCardComponent, (obj, index) => {
        return (_openBlock(), _createBlock(_component_a_col, {
          key: obj.name,
          span: 24,
          xl: {
        span:
          obj.name === 'ImageCheckCard' || obj.name === 'ImageMappingCard'
            ? 24
            : _ctx.getCardComponent.length === index + 1 && index % 2 === 0
            ? 24
            : 12,
      }
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(obj.name), _mergeProps({
              ref_for: true,
              ref: (el) => (_ctx.childrenRef[index] = el)
            }, obj.props ?? {}, _toHandlers(obj.event ?? {})), {
              scenariooption: _withCtx(() => [
                _renderSlot(_ctx.$slots, "scenariooption")
              ]),
              scenarioinput: _withCtx(() => [
                _renderSlot(_ctx.$slots, "scenarioinput")
              ]),
              _: 2
            }, 1040))
          ]),
          _: 2
        }, 1032, ["xl"]))
      }), 128)),
      _createVNode(_component_a_col, { span: 24 }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}