
import { defineComponent, ref, watch } from 'vue';

const formatter = (value: number) => {
  return `${value}%`;
};

const makeupParamOptions = [
  { key: 'styleValue', label: 'Makeup similarity' },
  { key: 'portionValue', label: 'Ratio of skin tone' },
  { key: 'eyeStyleValue', label: 'Similarity of eye makeup' },
  { key: 'eyePortionValue', label: 'Ratio of eye shape' },
  { key: 'mouthStyleValue', label: 'Similarity of lip makeup' },
  { key: 'mouthPortionValue', label: 'Ratio of mouth shape' },
];
export default defineComponent({
  props: {
    fileLength: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  setup(props) {
    watch(
      () => props.fileLength,
      (newVal) => {
        if (newVal > 2) {
          isGenFace.value = true;
        }
      }
    );

    const isGenFace = ref(false);
    const duoFacesRatio = ref<number>(50);

    const duoParams = ref<number[]>(Array(18).fill(50));
    const makeUpParams = ref<number[]>(Array(6).fill(50));
    const multiFacesRatio = ref<number[]>(Array(10).fill(50));

    const onChangeDuoFacesRatio = (value: number) => {
      duoParams.value = duoParams.value.map(() => 100 - value);
    };

    const onSubmit = () => {
      if (props.fileLength < 3) {
        if (isGenFace.value) {
          return {
            api: 'image-results/virtual-skin-simples',
            params: { mixtures: duoParams.value.map((value) => value / 100) },
          };
        } else {
          const emitValue = makeupParamOptions.reduce((acc, curr, index) => {
            return { ...acc, [curr.key]: makeUpParams.value[index] };
          }, {});

          return {
            api: 'image-results/virtual-skin-makeups',
            params: emitValue,
          };
        }
      } else {
        let mixtures = multiFacesRatio.value
          .slice(0, props.fileLength)
          .map((value) => value / 100);

        return {
          api: 'image-results/virtual-skins',
          params: {
            mixtures: mixtures,
          },
        };
      }
    };

    return {
      makeupParamOptions,
      // state
      isGenFace,
      makeUpParams,
      duoFacesRatio,
      duoParams,
      multiFacesRatio,

      // func
      formatter,
      onChangeDuoFacesRatio,
      onSubmit,
    };
  },
});
