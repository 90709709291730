
import { defineComponent, ref } from 'vue';
export default defineComponent({
  props: {
    text: {
      type: String,
      required: false
    }
  },
  emits: ['changeText'],
  setup(props, {emit}) {
    const onChange = (e: { target: { value: string; }; }) => {
      emit('changeText', e.target.value)
    }

    return {
      onChange
    }
  }

});
