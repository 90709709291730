
import { defineComponent, onBeforeMount, ref } from 'vue';

import { useRoute } from 'vue-router';
import { useCookies } from 'vue3-cookies';

import axios from '@/utils/axios';

interface IScenario {
  value: string;
  label: string;
  uid: number;
}

export default defineComponent({
  components: {
    // ScenarioBuilder
  },
  props: {
    title: { type: String, required: false, default: 'Scenario' },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    scenarioUrl: {
      type: String,
      required: false,
      default: undefined,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    scenarioList: {
      type: Array as () => IScenario[],
      required: false,
      default: () => [],
    },
    description: {
      type: String,
      required: false,
      default: '** Last selected scenario is saved.',
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const { cookies } = useCookies();
    const { name: routeName } = useRoute();

    const scenario = ref<IScenario>();
    const scenarioOptions = ref<IScenario[]>([]);

    onBeforeMount(async () => {
      let options: IScenario[] = [];
      const savedScenario = JSON.parse(
        cookies.get(String(routeName) || props.scenarioList[0].value)
      );

      if (props.scenarioUrl) {
        const { data } = await axios.get(`/${props.scenarioUrl}`, {
          params: {
            limit: 100,
            size: 1,
          },
        });
        if (data.results) {
          options = data.results.map((obj: { id: number; nickName: string }) => {
            return { label: obj.nickName, value: obj.nickName, uid: obj.id };
          });
        } else {
          options = data.items.map((obj: { id: number; nickName: string }) => {
            return { label: obj.nickName, value: obj.nickName, uid: obj.id };
          });
        }
      } else {
        options = props.scenarioList;
      }
      scenarioOptions.value = options;

      const index = options.findIndex((obj) => obj.value === savedScenario);
      scenario.value = options[index == -1 ? 0 : index];
    });

    const onChangeScenario = (value: string) => {
      cookies.set(String(routeName), JSON.stringify(value));
      scenario.value = scenarioOptions.value.filter(
        (obj) => obj.value === value
      )[0];
    };

    const onSubmit = async () => {
      return { scenario: scenario.value };
    };

    const onClick = () => {
      emit('click', scenario.value);
    };

    return {
      scenario,
      scenarioOptions,
      // func
      onChangeScenario,
      onSubmit,
      onClick,
    };
  },
});
