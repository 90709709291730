
import { computed, defineComponent, ref, watch } from 'vue';

import { getImageUrl } from '@/utils/mediaUrl';

import { IMediaFile } from './MediaCard.vue';

export default defineComponent({
  components: {
    // CheckOutlined,
  },
  props: {
    facesArray: {
      type: Array as () => { id: string; mediaUrl: string }[],
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const faceTokens = ref<string[]>([]);
    const isHover = ref(false);
    const selectedImages = ref<{ id: string; mediaUrl: string }[]>([]);
    watch(
      () => props.facesArray,
      () => {
        selectedImages.value = [];
      }
    );

    const loadFace = () => {
      emit('click');
    };

    const onChangeTargetImage = (
      info: { fileList: IMediaFile[] },
      index: number
    ) => {
      if (info.fileList.length === 0) {
        return;
      }
      let faces = info.fileList[0].faces;
      if (typeof info.fileList[0].faces === 'string') {
        faces = JSON.parse(info.fileList[0].faces);
      }

      if (Array.isArray(faces)) {
        faceTokens.value[index] = faces[0].mediaUrl;
      }
    };

    const setClick = (face: { id: string; mediaUrl: string }) => {
      const hasImage = selectedImages.value.some(
        (image) => image.id === face.id
      );

      if (hasImage) {
        selectedImages.value = selectedImages.value.filter(
          (image) => image.id !== face.id
        );
      } else {
        selectedImages.value.push(face);
      }
    };

    const onSubmit = async () => ({
      faces: selectedImages.value,
    });

    const getSelectedImage = computed(
      () => (face: { id: string; mediaUrl: string }) =>
        selectedImages.value.some((image) => image.id === face.id)
    );
    return {
      //state
      isHover,
      getImageUrl,
      // funcs
      setClick,
      loadFace,
      onChangeTargetImage,
      onSubmit,

      // computed
      getSelectedImage,
    };
  },
});
