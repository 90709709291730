
import { defineComponent, ref } from 'vue';

import { RightOutlined } from '@ant-design/icons-vue';

import { getImageUrl } from '@/utils/mediaUrl';

import MediaCard, { IMediaFile } from './MediaCard.vue';

export default defineComponent({
  components: {
    RightOutlined,
    MediaCard,
  },
  props: {
    facesArray: {
      type: Array as () => { id: string; mediaUrl: string }[],
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const newFaces = ref<any>(
      new Array(props.facesArray.length).fill(undefined)
    );

    const loadFace = () => {
      emit('click');
    };

    const onChangeTargetImage = (
      info: { fileList: IMediaFile[] },
      index: number
    ) => {
      newFaces.value[index] = info.fileList[0].faces?.[0];
    };

    const onSubmit = async () => {
      return {
        newFaces: newFaces.value,
        oldFaces: props.facesArray,
      };
    };

    return {
      getImageUrl,
      // funcs
      loadFace,
      onChangeTargetImage,
      onSubmit,
    };
  },
});
