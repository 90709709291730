
import { computed, defineComponent, ref } from 'vue';
import type { Component } from 'vue';

import type { UploadChangeParam, UploadFile } from 'ant-design-vue';

import ImageCheckCard from './ImageCheckCard.vue';
import ImageMappingCard from './ImageMappingCard.vue';
import KioskControlCard from './KioskControlCard.vue';
import MakeupControlCard from './MakeupControlCard.vue';
import MediaCard from './MediaCard.vue';
import ScenarioCard from './ScenarioCard.vue';
import TextInputCard from './TextInputCard.vue';

type tChild = Array<
  | ({ onSubmit?: () => void | Promise<any>; disabled?: boolean } & {
      uploaderProps: {
        isTemp: boolean;
        max: number;
        min: number;
      };
      fileList: (UploadFile & {
        faces: { id: string; mediaUrl: string }[];
      })[];
      min: number;
    } & Component)
  | undefined
  | null
>;

export default defineComponent({
  components: {
    MakeupControlCard,
    KioskControlCard,
    MediaCard,
    ImageMappingCard,
    ImageCheckCard,
    ScenarioCard,
    TextInputCard,
  },
  props: {
    type: {
      type: Array as () => {
        name: string;
        props?: { [key: string]: any };
        span?: number;
        event?: {
          [key: string]: (
            info?: UploadChangeParam<UploadFile<unknown>>
          ) => void;
        };
      }[],
      required: true,
    },
  },
  emits: ['select'],
  setup(props, { emit }) {
    const childrenRef = ref<tChild>([]);
    const facesArray = ref<{ id: string; mediaUrl: string }[]>([]);

    const loadFace = () => {
      const faces = childrenRef.value[0]?.fileList?.[0].faces || [];
      facesArray.value = faces;
    };

    const onSelect = (e: any) => {
      emit('select', e);
    };

    const getCardComponent = computed(() => {
      return props.type.map(({ name, props, event }) => {
        if (name === 'scenario') {
          return {
            name: 'ScenarioCard',
            props: {
              ...props,
              disabled: getDisableSubmit.value,
            },
            event,
          };
        }

        if (name === 'image' || name === 'video' || name === 'audio') {
          return {
            name: 'MediaCard',
            props: {
              ...props,
              title: name.charAt(0).toUpperCase() + name.slice(1),
              mediaType: name,
            },
            event: { ...event, select: onSelect },
          };
        }

        if (name === 'ImageMappingCard' || name === 'ImageCheckCard') {
          return {
            name,
            props: {
              ...props,
              facesArray: facesArray.value,
              disabled: childrenRef.value[0]?.fileList?.length !== 1,
            },
            event: {
              ...event,
              click: loadFace,
            },
          };
        } else if (name === 'KioskControlCard') {
          return {
            name,
            props: {
              ...props,
              disabled: getDisableSubmit.value,
            },
            event: {
              ...event,
            },
          };
        } else if (name === 'TextInputCard') {
          return {
            name,
            props: {
              ...props,
              disabled: getDisableSubmit.value,
            },
            event: {
              ...event,
            },
          };
        }

        return {
          name,
          props,
          event,
        };
      });
    });

    const submit = async () => {
      const mergedValues = await childrenRef.value.reduce(async (acc, curr) => {
        return { ...(await acc.then()), ...(await curr?.onSubmit?.()) };
      }, Promise.resolve({}));

      return mergedValues;
    };

    const getDisableSubmit = computed(() => {
      return !childrenRef.value.every((ref) => {
        if (!ref?.fileList) {
          return true;
        }
        if (
          ref.fileList?.filter(({ status }) => status !== 'done').length > 0
        ) {
          return false;
        }
        return ref.fileList?.length >= (ref?.uploaderProps?.min || 1);
      });
    });

    return {
      childrenRef,
      facesArray,
      // computed
      getCardComponent,
      // func
      submit,
      // computed
      getDisableSubmit,
    };
  },
});
